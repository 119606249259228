<div
  class="spot-modal op-file-picker"
  data-qa-selector="op-files-picker-modal"
>
  <div class="spot-modal--header">
    <span class="spot-modal--header-title">{{text.header}}</span>
    <button
      type="button"
      class="button button_no-margin spot-modal--header-button"
      (click)="openStorageLocation()"
    >
      <span>{{text.buttons.openStorage()}}</span>
      <span class="spot-icon spot-icon_external-link"></span>
    </button>
  </div>

  <div class="spot-divider"></div>

  <div class="spot-modal--body spot-container op-file-picker--modal-body">
    <spot-breadcrumbs [content]="breadcrumbs"></spot-breadcrumbs>

    <ng-container *ngIf="(loading$ | async) === false; else loadingTemplate">
      <ul
        class="spot-list spot-list_compact op-file-list op-file-picker--scrollable-content"
        data-qa-selector="op-files-picker-modal--file-list"
      >
        <li
          *ngFor="let file of listItems$ | async; let i = index;"
          class="spot-list--item op-file-list--item"
          data-qa-selector="op-file-list--item"
          op-storage-file-list-item
          [content]="file"
        ></li>
      </ul>
    </ng-container>

    <ng-template #loadingTemplate>
      <op-loading-file-list class="spot-body-big"></op-loading-file-list>
    </ng-template>
  </div>

  <div class="spot-action-bar">
    <div class="spot-action-bar--right">
      <button
        type="button"
        class="button spot-action-bar--action"
        [textContent]="text.buttons.cancel"
        (click)="closeMe()"
      ></button>
      <button
        type="button"
        class="button spot-action-bar--action -highlight"
        data-qa-selector="op-files-picker-modal--confirm"
        [disabled]="!canChooseLocation"
        [textContent]="text.buttons.submit"
        (click)="chooseLocation()"
      ></button>
    </div>
  </div>
</div>
